import log, { levels } from 'loglevel';

export const LOG_LEVEL_STORAGE_KEY = `${process.env.REACT_APP_STORAGE_PREFIX}:logLevel`;

// TRACE: 0;
// DEBUG: 1;
// INFO: 2;
// WARN: 3;
// ERROR: 4;
// SILENT: 5;
export default function setupLog() {
  const level =
    process.env.NODE_ENV === 'development'
      ? levels.TRACE
      : localStorage.getItem(LOG_LEVEL_STORAGE_KEY) ?? levels.INFO;

  localStorage.setItem(LOG_LEVEL_STORAGE_KEY, level);
  log.setLevel(level != null ? +level : levels.INFO, false);
}
