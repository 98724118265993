import log from 'loglevel';
import { notification, Modal } from 'antd';
import axios from 'axios';
// import { getAuth, getIdToken } from 'firebase/auth';
import { toJson } from 'shared/json';
export default function setupAxios() {
  let timer;
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      logoutAction();
    }, 1800000); /* 1800000半小時 */
  };
  const resetTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
  };
  const logoutAction = () => {
    Modal.info({
      content: (
        <div>
          <p>{`連線逾時請重新登入`}</p>
        </div>
      ),
      onOk() {
        window.location.reload();
      },
    });
  };
  // set request base url
  axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

  // request interceptor
  axios.interceptors.request.use(async (request) => {
    log.debug(
      'axios:request',
      request.method,
      request.url,
      request.data || request.params
    );
    resetTimer();
    if (request?.url !== '/auth/signoff/') {
      handleLogoutTimer();
    }
    // check firebase auth token
    // const firebaseAuth = getAuth();

    // if (firebaseAuth?.currentUser) {
    //   try {
    //     // https://firebase.google.com/docs/reference/js/auth.md?authuser=0#getidtoken
    //     // Returns the current token if it has not expired or if it will not
    //     // expire in the next five minutes. Otherwise, this will refresh the
    //     // token and return a new one.
    //     const token = await getIdToken(firebaseAuth?.currentUser);
    //     request.headers.authorization ??= `JWT ${token}`;
    //   } catch (e) {
    //     log.error('axios:getIdToken', e);
    //   }
    // }

    return request;
  });

  // response interceptor
  axios.interceptors.response.use(
    (response) => {
      return Promise.resolve(response.data);
    },
    (error) => {
      log.error('axios:response.error', { error });
      const info = error.response?.data?.info ?? {};
      // TODO: message path might be different
      notification.error({
        message: info.code || error.message,
        description: info.msg || defaultHttpErrorMessage(error),
      });
      return Promise.reject(error.response?.data);
    }
  );
}

function defaultHttpErrorMessage(error) {
  return error.response ? toJson(error.response.data) : '';
}
